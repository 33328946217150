import React, { useEffect, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { useData } from '../../Context/Context';
import API from '../../libs/ServerAPIs';
import HistoryIcon from '@mui/icons-material/History';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    Box,
    Button,
} from '@mui/material';

function IdleTimerContainer() {
    /* VARIABLE DECLARATION
    -------------------------------------------------------------------------------------*/
    const {
        userData,
        auth,
        activeSession,
        selectedBranch,
        showCustomerLocationsDropdown,
        selectedBranchLocations,
        selectedAllLocations,
        userBranchLocations,
        primaryUser,
        sessionTimerRef,
    } = useData(useData);
    const [, setUserDetails] = userData;
    const [, setPrimaryUser] = primaryUser;
    const [, setIsAuth] = auth;
    const [isActiveSession, setIsActiveSession] = activeSession;
    const [, setSelectedBranch] = selectedBranch;
    const [, set_selected_branch_locations] = selectedBranchLocations;
    const [, set_user_branch_locations] = userBranchLocations;
    const [, set_selected_all_locations] = selectedAllLocations;
    const [, set_show_customer_locations_dropdown] =
        showCustomerLocationsDropdown;
    const [openModal, setOpenModal] = useState(false);
    const history = useNavigate();

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    // useEffect(() => {

    // }, [])

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/

    const handleStorageChange = (event) => {
        // console.log(event, 'Storage EVENT generated');
        if (event.key === 'idle-logout') {
            setOpenModal(true);
        }
    };

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {
        // Add event listener for storage changes
        window.addEventListener('storage', handleStorageChange);
        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    function onIdle() {
        // console.log('onIdle triggered!');
        if (isActiveSession) {
            API.get('/api/v1/auth/logout').then((res) => {
                if (res.success) {
                    localStorage.setItem('idle-logout', true);
                    // window.dispatchEvent(new Event("storage"));
                    if (res?.redirectURL) {
                        if (
                            res?.redirectURL?.includes(
                                'https://login.microsoftonline.com'
                            )
                        ) {
                            window.open(res.redirectURL);
                        } else {
                            window.open(res.redirectURL, '_blank');
                        }
                    }
                    setIsActiveSession(false);
                    setOpenModal(true);
                }
            });
        }
    }

    function closeModal() {
        setIsAuth(false);
        setUserDetails(null);
        setPrimaryUser(null);
        set_user_branch_locations([]);
        setSelectedBranch(null);
        set_selected_all_locations(false);
        set_selected_branch_locations([]);
        set_show_customer_locations_dropdown(false);
        localStorage.clear();
        // history.replace('/');
        history('/',{replace:true});
        setOpenModal(false);
    }

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <>
            <IdleTimer
                key="idleTimer"
                startOnMount={true}
                ref={sessionTimerRef}
                timeout={1000 * 60 * 35}
                onIdle={onIdle}
                debounce={500}
            />

            <Dialog
                open={openModal}
                onClose={closeModal}
                fullWidth
                disableEscapeKeyDown={true}
                disableBackdropClick
                sx={{
                    '& .MuiPaper-root': {
                        maxWidth: '320px',
                    },
                }}
            >
                <DialogContent sx={{ p: '32px' }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <HistoryIcon fontSize="large" />
                    </Box>
                    <DialogContentText sx={{ textAlign: 'center' }}>
                        <Box
                            component="span"
                            sx={{
                                fontSize: '16px',
                                lineHeight: '1.4',
                                display: 'block',
                            }}
                        >
                            Your session has expired <br />
                            due to inactivity
                        </Box>
                        <Box
                            component="span"
                            sx={{ mt: '24px', display: 'block' }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={closeModal}
                            >
                                Login
                            </Button>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default IdleTimerContainer;
